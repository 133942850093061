var trackAnalyticsClick = function () {
  var link = $(this);

  try {
    var identifier = link.attr("id"),
      href = link.attr("href"),
      label = link.attr("data-label"),
      category = "";

    if (typeof href != "undefined") {
      if (typeof label == "undefined") {
        label = label + " [href]:" + href;
      }

      if (href[0] == "#") {
        category = "Anchor";
      } else {
        category = "Link";
      }
    }

    sendAnalyticsEvent(category, "clicked", label);
  } catch (err) {}
};

export var sendAnalyticsEvent = function (category, action, label) {
  try {
    ga("send", "event", category, action, label);
  } catch (err) {}
};

var hookUpAnalyticsLinkTracking = function () {
  $("a").each(function () {
    var link = $(this);

    if (link.attr("data-tracking-attached") != "true") {
      link.click(trackAnalyticsClick);
      link.attr("data-tracking-attached", "true");
    }
  });
};

$(document).ready(function () {
  hookUpAnalyticsLinkTracking();
});

$(document).ajaxComplete(function () {
  hookUpAnalyticsLinkTracking();
});
