import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['keywords']

  initialize() {
    this.requests = []
    this.courseGuid = $('#course_guid').val()
    this.results = $('#search_results')
    this.main = $('main')
  }

  search = _.debounce(this.request, 500)

  showMain() {
    this.results.hide()
    this.main.show()

    $('#close-icon').off('click')
  }

  keywords() {
    return this.keywordsTarget.value.trim()
  }

  url(keywords) {
    return `/study/${this.courseGuid}/lessons/search?keywords=${keywords}`
  }

  request() {
    const keywords = this.keywords()

    if (keywords.length > 0) {
      const request = $.get(this.url(keywords), (response) => {
        this.render(response)

        this.show()
      })
      this.requests.push(request)
    }
    else {
      this.closeSearch()
    }
  }

  render(response) {
    const leftCol = $(response).find('#left-col')
    const closeIcon = $(response).find('#close-icon')

    closeIcon.on('click', () => {
      this.closeSearch()
    })

    this.results.html(leftCol).prepend(closeIcon)
  }

  show() {
    if(this.results.css('display') == 'block') { return }

    this.results.show()
    this.main.hide()
  }

  closeSearch() {
    this.keywordsTarget.value = ''
    this.abortPendingRequests()
    this.showMain()
  }

  abortPendingRequests() {
    this.requests.map((request) => request.abort())
    this.requests = []
  }

  disconnect() {
    this.abortPendingRequests()
  }
}
