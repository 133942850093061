import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const timestamp = new Date().toISOString()

    fetch('/artifact/?' + timestamp).then((response) => {
      response.text().then((body) => {
        $('#app').html(body)
      })
    })
  }
}
