import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cardLaunchLink', 'lessonCard']

  connect() {
    if (this.hasCardLaunchLinkTarget) {
      const courseLink = $(this.cardLaunchLinkTarget).attr('href')
      courseLink && this.updateLessonCard(courseLink)
    }
  }

  updateLessonCard = (courseLink) => {
    $(this.lessonCardTarget).css('cursor', 'pointer')
    courseLink &&
      $(this.lessonCardTarget).on('click', function () {
        window.location = courseLink
      })
  }
}
