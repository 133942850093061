import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  showDebug() {
    const $debugs = $('.debug')
    $debugs.is(':visible') ? $debugs.hide(200) : $debugs.show(200)
  }

  connect() {
    $(document.body).on(
      'keyup',
      function (e) {
        if (e.ctrlKey && e.key == 'd') {
          this.showDebug()
        }
      }.bind(this),
    )
  }
}
