import { Controller } from '@hotwired/stimulus'
import Strings from '../src/strings'
import ClipboardJS from 'clipboard'

export default class extends Controller {
  connect() {
    const clipboard = new ClipboardJS('.share .btn');

    clipboard.on('success', () => {
      alert(Strings.artifact.url_copied)
    })

    clipboard.on('error', () => {
      alert(Strings.artifact.url_copy_failed)
    })
  }
}
