// Super basic Strings for JS
//
// Load up explicitly in your Rails views using a helper to pull keys from I18n:
// ```
//    :javascript
//        Strings.load(#{i18n_to_json('lesson')})
// ```
//
// Then use in your Javascript:
//
// ```
// import Strings from '../src/strings'
//
// Strings.lesson.title
// ```
export default class {
  static load(keys) {
    Object.assign(this, keys)
  }
}
