import { Controller } from '@hotwired/stimulus'

const sections = 5
const parts = new Array(sections).fill('')
const pages = parts.map((page, index) => `section${index + 1}`)
const paginationDots = parts.map((dot, index) => `dot${index + 1}`)

export default class extends Controller {
  static targets = [...pages, ...paginationDots, 'nextPageButton', 'prevPageButton']

  min = 1
  max = 5

  getCurrentPage() {
    return $('#section_container .section').not('.hidden')
  }

  getAnimationClasses(direction) {
    const classIn = `slide-${direction}-in`
    const classOut = `slide-${direction}-out`
    return { classIn, classOut }
  }

  updatePage = ($nextPage, $currentPage, direction) => {
    const { classIn, classOut } = this.getAnimationClasses(direction)
    $currentPage
      .removeClass()
      .addClass(`section ${classOut}`)
      .off('animationend')
      .on('animationend', function () {
        $(this).removeClass(classOut).addClass('hidden')
        $(this).off('animationend')
        $nextPage.removeClass('hidden')
      })
    $nextPage.removeClass().addClass(`section ${classIn}`)
  }

  setClasses = (current, className) => {
    current === 1 && $(this.prevPageButtonTarget).addClass(className)
    current > 1 && $(this.prevPageButtonTarget).removeClass(className)
    current === 5 && $(this.nextPageButtonTarget).addClass(className)
    current < 5 && $(this.nextPageButtonTarget).removeClass(className)
  }

  updatePagination = ($nextPage, $currentPage, direction) => {
    this.setClasses($nextPage.data('id'), 'hidden')
    this.updateDots($nextPage.data('id'))
    this.updatePage($nextPage, $currentPage, direction)
  }

  updateDots = (next) => {
    $('.place-dot').removeClass('current')
    const nextTarget = `dot${next}Target`
    $(this[nextTarget]).addClass('current')
  }

  leftPageNumber = (current) => {
    const currentInt = parseInt(current)
    return currentInt + 1 <= this.max ? currentInt + 1 : this.min
  }

  rightPageNumber = (current) => {
    const currentInt = parseInt(current)
    return currentInt - 1 >= this.min ? currentInt - 1 : this.max
  }

  getPageById(id) {
    return $(this[`section${id}Target`])
  }

  // page event
  changePage = (e) => {
    const $currentPage = this.getCurrentPage()
    if (!$currentPage) return

    const currentPageNumber = $currentPage.data('id')
    const { direction } = e.target.dataset
    const nextPageNumber = this[`${direction}PageNumber`](currentPageNumber)
    const $nextPage = this.getPageById(nextPageNumber)

    this.updatePagination($nextPage, $currentPage, direction)
  }

  gotoPage = (e) => {
    const nextPageNumber = parseInt(e.target.dataset.id)
    const $currentPage = this.getCurrentPage()

    // do nothing if same page or parent div clicked.
    if (!nextPageNumber || nextPageNumber === $currentPage.data('id')) return

    const $nextPage = this.getPageById(nextPageNumber)
    const direction =
      parseInt($currentPage.data('id')) >= parseInt($nextPage.data('id')) ? 'right' : 'left'
    this.updatePagination($nextPage, $currentPage, direction)
  }
}
