/* eslint no-console:0 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@hotwired/turbo-rails'
import 'jquery'

import '../src/helpers/yga'
import 'controllers'

import Strings from '../src/strings'

window.Strings = Strings
