import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['debug']

  connect() {
    this.debugTargets.map((elem) => {
      $(elem).on('click', function (e) {
        e.stopPropagation()
      })
    })
  }
}
