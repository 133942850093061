import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['metaContainer', 'listItemCard', 'cardLaunchLink']

  attachModalListener() {
    const modalCover = document.createElement('div')
    modalCover.classList.add('cover-it')
    const body = document.querySelector('body')
    body.prepend(modalCover)

    modalCover.addEventListener('click', this.modalListener.bind(this))
  }

  toggleOpenClass($element) {
    $element.toggleClass('open')
  }

  modalListener(event) {
    $('.cover-it').remove()
    const $target = $(this.metaContainerTarget).find('.meta.open')
    this.toggleOpenClass($target)
  }

  toggle(event) {
    event.stopPropagation()
    // stop propagation to the lesson_card
    if ($(event.target).hasClass('pdf')) return
    const $target = $(event.target).find('.meta')
    if (!$('.cover-it').length > 0) {
      this.toggleOpenClass($target)
      this.attachModalListener($target)
    } else {
      this.modalListener(event)
    }
  }
}
